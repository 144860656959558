import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getUsers() {
    return this.http.get<any>(`${this.BASE_URL}api/tenant/users`);
  }
}
