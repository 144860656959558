import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromMediaDetail from '../reducers/media-detail.reducer';
import * as fromRoot from '../../../store';

export const getMediaDetailsState = createSelector(fromFeature.getMediaState, (state: fromFeature.MediaState) => state.mediaDetail);

export const getMediaDetailItem = createSelector(getMediaDetailsState, fromMediaDetail.getMediaDetailItem);
export const getMediaDetailLoaded = createSelector(getMediaDetailsState, fromMediaDetail.getMediaDetailLoaded);
export const getMediaDetailLoading = createSelector(getMediaDetailsState, fromMediaDetail.getMediaDetailLoading);

export const getSelectedMediaDetailLoading = createSelector(getMediaDetailLoading, fromRoot.getRouterState, (loading, router): boolean => {
  return router.state && loading === +(router.state.params?.['id'] || router.state.data?.['id']);
});

export const getSelectedMediaDetailLoaded = createSelector(getMediaDetailLoaded, fromRoot.getRouterState, (loaded, router): boolean => {
  return router.state && loaded === +(router.state.params?.['id'] || router.state.data?.['id']);
});
