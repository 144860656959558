import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromTenant from './tenant-users.reducer';

export const featureKey = 'Tenant';

export interface TenantState {
  tenantUsers: fromTenant.TenantUsersState;
}

export const reducers: ActionReducerMap<TenantState> = {
  tenantUsers: fromTenant.TenantUsersReducer,
};

export const getTenantState = createFeatureSelector<TenantState>(featureKey);
