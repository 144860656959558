import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RecurrencePattern } from '../../recurrence/models/recurrence-pattern.model';

@Injectable({
  providedIn: 'root',
})
export class RecurrenceService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getRecurringDates(startDate: Date, endTime: Date | null, pattern: RecurrencePattern) {
    return this.http.post<Date[]>(
      `${this.BASE_URL}api/recurring-dates?startDate=${startDate.toISOString()}&endTime=${endTime?.toISOString() || ''}`,
      pattern
    );
  }
}
