import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MediaService } from '../../services';
import * as MediaActions from '../actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Go } from 'src/app/store';

export const loadMediaDetail$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService)) => {
    return actions$.pipe(
      ofType(MediaActions.LoadMediaDetail),
      switchMap(({ id }) =>
        service.getMediaById(id).pipe(
          map(media => MediaActions.LoadMediaDetailSuccess({ media })),
          catchError(error => of(MediaActions.LoadMediaDetailFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const saveMediaDetail$ = createEffect(
  (actions$ = inject(Actions), service = inject(MediaService)) => {
    return actions$.pipe(
      ofType(MediaActions.SaveMediaDetail),
      switchMap(({ id, anchor, altText, caption }) =>
        service.saveMedia(id, anchor, altText, caption).pipe(
          map(media => MediaActions.SaveMediaDetailSuccess({ media })),
          catchError(error => of(MediaActions.SaveMediaDetailFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const saveMediaDetailSuccess$ = createEffect(
  (actions$ = inject(Actions), sb = inject(MatSnackBar), lang = inject(LanguageService)) => {
    const dict = new FromDictionaryPipe(lang);
    return actions$.pipe(
      ofType(MediaActions.SaveMediaDetail),
      tap(({ id }) => sb.open(dict.transform('form:opslaan-geslaagd'), dict.transform('form:opslaan-geslaagd-knop'), { duration: 2000 }))
    );
  },
  { functional: true, dispatch: false }
);
