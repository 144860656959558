import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TenantService } from '../../services';
import * as TenantActions from '../actions';
import { catchError, map, of, switchMap } from 'rxjs';

export const loadTenantUsers$ = createEffect(
  (actions$ = inject(Actions), service = inject(TenantService)) => {
    return actions$.pipe(
      ofType(TenantActions.LoadTenantUsers),
      switchMap(() =>
        service.getUsers().pipe(
          map(users => TenantActions.LoadTenantUsersSuccess({ users })),
          catchError(error => of(TenantActions.LoadTenantUsersFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
