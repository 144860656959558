import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/media-detail.action';
import { Media } from '../../models';
import { state } from '@angular/animations';

export interface MediaDetailState {
  item: Media | null;
  loading: number | null;
  loaded: number | null;
  error?: any;
}

export const initialState: MediaDetailState = {
  item: null,
  loading: null,
  loaded: null,
};

export const MediaDetailReducer = createReducer(
  initialState,
  on(actions.LoadMediaDetail, (state: MediaDetailState, { id }) => {
    return {
      ...state,
      loading: id,
      loaded: null,
    };
  }),
  on(actions.LoadMediaDetailSuccess, (state: MediaDetailState, { media }) => {
    return {
      ...state,
      item: media,
      loading: null,
      loaded: media.id || media.Id,
    };
  }),
  on(actions.LoadMediaDetailFail, (state: MediaDetailState, { error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      error,
    };
  }),
  on(actions.SaveMediaDetail, (state: MediaDetailState, { id }) => {
    return {
      ...state,
      loading: id,
    };
  }),
  on(actions.SaveMediaDetailSuccess, (state: MediaDetailState, { media }) => {
    return {
      ...state,
      item: media,
      loading: null,
    };
  }),
  on(actions.SaveMediaDetailFail, (state: MediaDetailState, { error }) => {
    return {
      ...state,
      error,
    };
  })
);

export const getMediaDetailItem = (state: MediaDetailState) => state.item;
export const getMediaDetailLoading = (state: MediaDetailState) => state.loading;
export const getMediaDetailLoaded = (state: MediaDetailState) => state.loaded;
