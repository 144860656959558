import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromTenant from '../reducers/tenant-users.reducer';

export const getTenantUsersState = createSelector(fromFeature.getTenantState, (state: fromFeature.TenantState) => state.tenantUsers);

export const getTenantUsersEntities = createSelector(getTenantUsersState, fromTenant.getTenantUsersEntities);
export const getTenantUsersLoaded = createSelector(getTenantUsersState, fromTenant.getTenantUsersLoaded);
export const getTenantUsersLoading = createSelector(getTenantUsersState, fromTenant.getTenantUsersLoading);

export const getAllTenantUsers = createSelector(getTenantUsersEntities, entities => {
  return Object.keys(entities).map((id: string) => entities[id]);
});

export const getOrderedTenantUsers = createSelector(getAllTenantUsers, tenantUsers => {
  return tenantUsers.sort((a, b) => a.lastName.localeCompare(b.lastName));
});
