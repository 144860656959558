import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/tenant-users.action';
import { CmsUser } from 'src/app/auth/models';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface TenantUsersState {
  entities: { [id: string]: CmsUser };
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: TenantUsersState = {
  entities: {},
  loading: false,
  loaded: false,
};

export const TenantUsersReducer = createReducer(
  initialState,
  on(actions.LoadTenantUsers, (state: TenantUsersState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(actions.LoadTenantUsersSuccess, (state: TenantUsersState, { users }) => {
    const entities = ToEntities(users, 'id', {});

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadTenantUsersFail, (state: TenantUsersState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getTenantUsersEntities = (state: TenantUsersState) => state.entities;
export const getTenantUsersLoading = (state: TenantUsersState) => state.loading;
export const getTenantUsersLoaded = (state: TenantUsersState) => state.loaded;
