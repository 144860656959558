import { createAction, props } from '@ngrx/store';
import { Media } from '../../models';

export const LoadMediaDetail = createAction('[Media] Load Media Detail', props<{ id: number }>());
export const LoadMediaDetailSuccess = createAction('[Media] Load Media Detail Success', props<{ media: Media }>());
export const LoadMediaDetailFail = createAction('[Media] Load Media Detail Fail', props<{ error: any }>());

export const SaveMediaDetail = createAction('[Media] Save Media Detail', props<{ id: number; anchor?: string | null; altText?: string | null; caption?: string | null }>());
export const SaveMediaDetailSuccess = createAction('[Media] Save Media Detail Success', props<{ media: Media }>());
export const SaveMediaDetailFail = createAction('[Media] Save Media Detail Fail', props<{ error: any }>());
